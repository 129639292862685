import React, { useState, useEffect, useRef } from "react";

//Material UI imports
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Pagination,
  Modal,
  Autocomplete,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import SortByPopover from "../components/SortByPopover";

//Firebase imports
import { db } from "../firebase";
import { ref, orderByChild, query, get } from "firebase/database";

//Component Imports
import { useDarkMode } from "../context/DarkModeContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "80%",
    sm: "60%",
    md: "50%",
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const createFilterOptions = (options, state) => {
  const { inputValue } = state;

  // If there's no value in the textfield, don't display any options
  if (!inputValue) {
    return [];
  }

  // If there is a value in the textfield, filter options based on input value
  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(inputValue.toLowerCase())
  );

  return filteredOptions;
};

const ModalRepo = ({ open, setOpen, sendItemCB }) => {
  const [songList, setSongList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [searchOn, setSearchOn] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchArr, setSearchArr] = useState([]);
  const [songTypePref, setSongTypePref] = useState("canto");
  const [normalSongArr, setNormalSongArr] = useState([]);
  const [normalSongNameData, setNormalSongNameData] = useState([]);
  const [hymnSongArr, setHymnSongArr] = useState([]);
  const [hymnSongNameData, setHymnSongNameData] = useState([]);
  const [countDisplay, setCountDisplay] = useState(false);
  const [pageState, setPageState] = useState(1);

  const [paginateArr, setPaginateArr] = useState([]);

  const searchInput = useRef(null);

  const { darkMode } = useDarkMode();

  const handleClose = () => {
    if (searchOn) {
      const newList = paginateArr.slice(paginateArr[0], 10);
      setPageCount(Math.ceil(paginateArr.length / 10));
      setSongList(newList);
      setSearchText("");
      setSearchOn(!searchOn);
    }

    setPageState(1);
    setCountDisplay(false);
    setSongTypePref("canto");
    setSongList(normalSongArr.slice(0, 10));
    setPaginateArr(normalSongArr);
    setPageCount(Math.ceil(Object.values(normalSongArr).length / 10));
    setOpen(false);
  };

  const handleListItemClick = (item) => {
    sendItemCB(item);
    handleClose();
  };

  const movePage = (currPage) => {
    setLoading(true);
    setSongList([]);

    if (searchOn) {
      const newList = searchArr.slice(
        10 * (currPage - 1),
        10 * (currPage - 1) + 10
      );
      setSongList(newList);
      setLoading(false);
    } else {
      setSongList(paginateArr.slice(10 * currPage - 10, 10 * currPage));
      setLoading(false);
    }
  };

  const cancelActiveSearch = () => {
    if (searchOn || searchText) {
      const newList = paginateArr.slice(paginateArr[0], 10);
      setPageCount(Math.ceil(paginateArr.length / 10));
      setSongList(newList);
      setSearchText("");
      setSearchOn(!searchOn);
      setPageState(1);
    }
  };

  const sortData = (choice) => {
    //cancel any active searches before sorting
    cancelActiveSearch();

    let tempArr;

    switch (choice) {
      case 0:
        tempArr = Array.from(paginateArr).sort((a, b) =>
          a.name < b.name ? -1 : 1
        );
        setSongList(tempArr.slice(0, 10));
        setPaginateArr(tempArr);
        setCountDisplay(false);
        setPageState(1);
        break;
      case 1:
        tempArr = Array.from(paginateArr).sort((a, b) =>
          a.name > b.name ? -1 : 1
        );
        setSongList(tempArr.slice(0, 10));
        setPaginateArr(tempArr);
        setCountDisplay(false);
        setPageState(1);
        break;
      case 2:
        tempArr = Array.from(paginateArr).sort((a, b) =>
          a.performNum < b.performNum ? -1 : 1
        );
        setSongList(tempArr.slice(0, 10));
        setPaginateArr(tempArr);
        setCountDisplay(true);
        setPageState(1);
        break;
      case 3:
        tempArr = Array.from(paginateArr).sort((a, b) =>
          a.performNum > b.performNum ? -1 : 1
        );
        setSongList(tempArr.slice(0, 10));
        setPaginateArr(tempArr);
        setCountDisplay(true);
        setPageState(1);
        break;
      default:
        break;
    }
  };

  //initial loading function
  useEffect(() => {
    const pageObj = query(ref(db, "articulos"), orderByChild("name"));
    let initTempArr = [];

    get(pageObj).then((snapshot) => {
      snapshot.forEach((item) => {
        if (item.val().type === "canto") {
          setNormalSongArr((current) => [...current, item.val()]);
          setNormalSongNameData((current) => [
            ...new Set([...current, item.val().name]),
          ]);
          initTempArr.push(item.val());
        } else {
          setHymnSongArr((current) => [...current, item.val()]);
          setHymnSongNameData((current) => [
            ...new Set([...current, item.val().name]),
          ]);
        }
      });

      setPageCount(Math.ceil(Object.values(initTempArr).length / 10));
      let tempArr = initTempArr.slice(0, 10);
      setSongList(tempArr);
      setPaginateArr(initTempArr);
    });

    setLoading(false);
  }, []);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose} aria-label="close">
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginY: { xs: 1, md: 2 },
            }}
          >
            <Autocomplete
              disablePortal
              filterOptions={createFilterOptions}
              freeSolo
              disableClearable
              options={
                songTypePref === "canto" ? normalSongNameData : hymnSongNameData
              }
              value={searchText}
              sx={{
                width: { xs: "100%", md: 360 },
                ".MuiInputBase-input": { fontSize: { xs: 12, md: 14 } },
                ".MuiInputLabel-root": { fontSize: { xs: 12, md: 14 } },
              }}
              onChange={(_, newValue, reason) => {
                if (reason === "selectOption") {
                  setSearchText(newValue);
                  setPageState(1);
                  setSearchOn(true);
                  const searchList = paginateArr.filter((i) =>
                    i.name.toLowerCase().includes(newValue.toLowerCase())
                  );
                  setSearchArr(searchList);
                  const newList = searchList.slice(0, 10);
                  setSongList(newList);
                  setPageCount(Math.ceil(searchList.length / 10));
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={searchInput}
                  label="Buscar"
                  size="small"
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.target.value) {
                      setSearchText(e.target.value);
                      setPageState(1);
                      setSearchOn(true);
                      const searchList = paginateArr.filter((i) =>
                        i.name
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      );
                      setSearchArr(searchList);
                      const newList = searchList.slice(0, 10);
                      setSongList(newList);
                      setPageCount(Math.ceil(searchList.length / 10));
                      searchInput.current.blur();
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: searchText ? (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                        <IconButton onClick={cancelActiveSearch}>
                          <ClearIcon />
                        </IconButton>
                      </React.Fragment>
                    ) : null,
                  }}
                />
              )}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              my: 1,
            }}
          >
            <Button
              onClick={() => {
                setCountDisplay(false);
                setPageState(1);
                setSongTypePref("canto");
                setSearchOn(false);
                setSearchText("");
                setSongList(normalSongArr.slice(0, 10));
                setPageCount(
                  Math.ceil(Object.values(normalSongArr).length / 10)
                );
                setPaginateArr(normalSongArr);
              }}
              disabled={songList.length === 0}
              sx={{
                mx: 1,
                backgroundColor: songTypePref === "canto" ? "green" : "grey",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
                fontSize: { xs: 12, md: 14 },
              }}
              size="small"
              variant="contained"
            >
              Cantos
            </Button>
            <Button
              onClick={() => {
                setCountDisplay(false);
                setPageState(1);
                setSongTypePref("himno");
                setSearchOn(false);
                setSearchText("");
                setSongList(hymnSongArr.slice(0, 10));
                setPageCount(Math.ceil(Object.values(hymnSongArr).length / 10));
                setPaginateArr(hymnSongArr);
              }}
              disabled={songList.length === 0}
              sx={{
                mx: 1,
                backgroundColor: songTypePref == "himno" ? "green" : "grey",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              size="small"
              variant="contained"
            >
              Himnos
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SortByPopover
              execFunc={sortData}
              disabled={songList.length === 0}
              stateData={songTypePref}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginY: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: { xs: "100%", md: 360 },
                border: 1,
                borderColor: "lightgrey",
              }}
            >
              <List disablePadding>
                {songList.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ListItem
                        secondaryAction={
                          <ListItemText>
                            {countDisplay ? item.performNum : null}
                          </ListItemText>
                        }
                        disablePadding
                      >
                        <ListItemButton
                          onClick={() => handleListItemClick(item)}
                          sx={{ px: 2, py: 0 }}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: { xs: 12, md: 14 },
                            }}
                            secondaryTypographyProps={{
                              fontSize: 12,
                            }}
                            style={{ color: darkMode ? "white" : "black" }}
                            primary={item.name}
                            secondary={item?.author || "Desconocido"}
                          />
                        </ListItemButton>
                      </ListItem>
                      {index !== songList.length - 1 && <Divider />}
                    </React.Fragment>
                  );
                })}
              </List>
            </Box>
          </Box>
          {isLoading && (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: { xs: 2, md: 0 },
            }}
          >
            <Pagination
              count={pageCount}
              page={pageState}
              color="primary"
              onChange={(_, value) => {
                movePage(value);
                setPageState(value);
              }}
              sx={{
                ".MuiPaginationItem-text": { fontSize: { xs: 12, md: 14 } },
              }}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default React.memo(ModalRepo);
