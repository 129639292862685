import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

//Firebase imports
import { db } from "../firebase";
import { ref, orderByChild, query, get } from "firebase/database";

import { useDarkMode } from "../context/DarkModeContext";
import Colors from "../data/Colors";

export default function Generator() {
  const songArr = useRef(new Array());
  const despacioArr = useRef(null);
  const rapidoArr = useRef(null);
  const [songChoice, setSongChoice] = useState("");
  const [songTypePref, setSongTypePref] = useState("off");
  const [songSpeedPref, setSongSpeedPref] = useState("off");
  const { darkMode } = useDarkMode();

  useEffect(() => {
    const songRef = query(ref(db, "articulos"), orderByChild("name"));

    get(songRef)
      .then((snapshot) => {
        snapshot.forEach((item) => {
          songArr.current.push(item.val());
        });
      })
      .then(() => {
        rapidoArr.current = songArr.current.filter((item) => {
          return item.speed == "rapido";
        });
        despacioArr.current = songArr.current.filter((item) => {
          return item.speed == "despacio";
        });
      });
  }, []);

  const setRandomSong = () => {
    switch (songSpeedPref) {
      case "rapido":
        if (songTypePref == "off") {
          setSongChoice(
            rapidoArr.current[
              Math.floor(Math.random() * rapidoArr.current.length)
            ].name
          );
        } else {
          const tempArr = rapidoArr.current.filter((item) => {
            return item.type == songTypePref;
          });

          setSongChoice(
            tempArr[Math.floor(Math.random() * tempArr.length)].name
          );
        }

        break;

      case "despacio":
        if (songTypePref == "off") {
          setSongChoice(
            despacioArr.current[
              Math.floor(Math.random() * despacioArr.current.length)
            ].name
          );
        } else {
          const tempArr = despacioArr.current.filter((item) => {
            return item.type == songTypePref;
          });

          setSongChoice(
            tempArr[Math.floor(Math.random() * tempArr.length)].name
          );
        }

        break;

      default:
        if (songTypePref == "off") {
          setSongChoice(
            songArr.current[Math.floor(Math.random() * songArr.current.length)]
              .name
          );
        } else {
          const tempArr = songArr.current.filter((item) => {
            return item.type == songTypePref;
          });

          setSongChoice(
            tempArr[Math.floor(Math.random() * tempArr.length)].name
          );
        }

        break;
    }
  };

  return (
    <div
      style={{
        backgroundColor: darkMode ? Colors.darkgrey : "white",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          py: 5,
        }}
      >
        <Button sx={{ p: 3 }} variant="contained" onClick={setRandomSong}>
          Generar Canto
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 5,
        }}
      >
        <Button
          sx={{
            backgroundColor: songSpeedPref == "rapido" ? "green" : "grey",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          variant="contained"
          onClick={() => setSongSpeedPref("rapido")}
        >
          Rapido
        </Button>
        <Button
          sx={{
            mx: 3,
            backgroundColor: songSpeedPref == "off" ? "green" : "grey",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          variant="contained"
          onClick={() => setSongSpeedPref("off")}
        >
          Apagado
        </Button>
        <Button
          sx={{
            backgroundColor: songSpeedPref == "despacio" ? "green" : "grey",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          variant="contained"
          onClick={() => setSongSpeedPref("despacio")}
        >
          Despacio
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          my: 5,
        }}
      >
        <Button
          sx={{
            backgroundColor: songTypePref == "canto" ? "green" : "grey",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          variant="contained"
          onClick={() => setSongTypePref("canto")}
        >
          Canto
        </Button>
        <Button
          sx={{
            mx: 3,
            backgroundColor: songTypePref == "off" ? "green" : "grey",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          variant="contained"
          onClick={() => setSongTypePref("off")}
        >
          Apagado
        </Button>
        <Button
          sx={{
            backgroundColor: songTypePref == "himno" ? "green" : "grey",
            "&:hover": {
              backgroundColor: "darkgreen",
            },
          }}
          variant="contained"
          onClick={() => setSongTypePref("himno")}
        >
          Himno
        </Button>
      </Box>

      <Typography
        sx={{
          color: darkMode ? "white" : "black",
          padding: 2,
          textAlign: "center",
        }}
        variant="h2"
      >
        {songChoice !== "" ? songChoice : "¡Presiona El Boton!"}
      </Typography>
    </div>
  );
}
