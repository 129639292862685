import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { db } from "../firebase";
import { get, ref as databaseRef } from "firebase/database";
import { useAuth } from "../context/AuthContext";
import { useDarkMode } from "../context/DarkModeContext";
import { updateCalendarEvents } from "../utils/DBFunctions";
import uuid from "react-uuid";
import "../Calendar.css";
import Colors from "../data/Colors";

const Calendar = () => {
  const { userID, isLoading, setLoading } = useAuth();
  const { darkMode } = useDarkMode();
  const [listOptions, setListOptions] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [newEvent, setNewEvent] = useState("");
  const [newConnectedList, setNewConnectedList] = useState("");
  const [editModes, setEditModes] = useState([]);
  const [editedEventTitles, setEditedEventTitles] = useState([]);
  const [editedEventLists, setEditedEventLists] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCalendarEvents = async () => {
      try {
        const calendarEventRef = databaseRef(db, "calendarEvents");
        setLoading(true);
        const snapshot = await get(calendarEventRef);
        if (snapshot.exists()) {
          setEvents(Object.values(snapshot.val()));
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchCalendarEvents();
  }, []);

  useEffect(() => {
    get(databaseRef(db, "coleccionDeListas")).then((snapshot) => {
      if (snapshot.exists()) {
        setListOptions(Object.values(snapshot.val()));
      }
    });
  }, []);

  const handleDayClick = (day) => {
    setSelectedDay(day);
    setEditModes([]);
    setEditedEventTitles([]);
  };

  const goToOtherList = (e, listID) => {
    e.preventDefault();
    navigate("/otherlists", {
      state: { listID: listID },
    });
  };

  // Map through the events to find days with events
  const daysWithEvents = events.reduce((acc, event) => {
    acc[event.date] = (acc[event.date] || 0) + 1;
    return acc;
  }, {});

  // Handle changing the month
  const changeMonth = (input) => {
    setCurrentMonth((prevMonth) => {
      const newMonth = prevMonth + input;
      if (newMonth < 1) {
        setCurrentYear((prevYear) => prevYear - 1);
        return 12;
      } else if (newMonth > 12) {
        setCurrentYear((prevYear) => prevYear + 1);
        return 1;
      }
      return newMonth;
    });
    setSelectedDay(null);
  };

  // Handle selecting a specific month
  const selectMonth = (event) => {
    const selectedMonth = parseInt(event.target.value, 10);
    setCurrentMonth(selectedMonth);
    setSelectedDay(null);
  };

  // Handle selecting a specific year
  const selectYear = (event) => {
    const selectedYear = parseInt(event.target.value, 10);
    setCurrentYear(selectedYear);
    setSelectedDay(null);
  };

  // Render the calendar days
  const renderCalendarDays = () => {
    const totalDays = new Date(currentYear, currentMonth, 0).getDate();
    const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1).getDay();
    const todaysDate = new Date()
      .toLocaleDateString("en-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const calendarDays = [];

    // Add the days of the week
    for (let i = 0; i < 7; i++) {
      calendarDays.push(
        <div
          key={`day-of-week-${i}`}
          style={{ color: darkMode && "white" }}
          className="day-of-week"
        >
          {daysOfWeek[i]}
        </div>
      );
    }

    // Add empty days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendarDays.push(
        <div
          key={`empty-${i}`}
          style={{ backgroundColor: darkMode && "gray" }}
          className="empty-day"
        />
      );
    }

    // Add the calendar days
    for (let day = 1; day <= totalDays; day++) {
      const date = `${currentYear.toString()}-${currentMonth
        .toString()
        .padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      const hasEvents = daysWithEvents[date];

      calendarDays.push(
        <div
          key={day}
          className={`calendar-day ${selectedDay === date ? "selected" : ""} ${
            hasEvents ? "has-events" : ""
          } ${todaysDate === date ? "todays-date" : ""}`}
          style={{ color: darkMode && "white" }}
          onClick={() => handleDayClick(date)}
        >
          {day}
        </div>
      );
    }

    return calendarDays;
  };

  const handleAddEvent = () => {
    if (selectedDay && newEvent.trim() !== "") {
      setLoading(true);
      const newEventObject = {
        date: selectedDay,
        title: newEvent,
        listID: newConnectedList,
      };
      setEvents([...events, newEventObject]);
      updateCalendarEvents([...events, newEventObject], setLoading);
      setNewEvent("");
      setEditModes([...editModes, false]);
    }
  };

  const handleEditEvent = (eventIndex, itemIndex) => {
    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes[eventIndex] = true;
      return newModes;
    });

    setEditedEventTitles((prevTitles) => {
      const newTitles = [...prevTitles];
      newTitles[eventIndex] = events[itemIndex].title;
      return newTitles;
    });

    setEditedEventLists((prevLists) => {
      const newLists = [...prevLists];
      newLists[eventIndex] = events[itemIndex].listID;
      return newLists;
    });
  };

  const handleSaveEvent = (eventIndex, itemIndex) => {
    setLoading(true);
    const updatedEvents = [...events];
    updatedEvents[itemIndex].title = editedEventTitles[eventIndex];
    updatedEvents[itemIndex].listID = editedEventLists[eventIndex];
    setEvents(updatedEvents);
    updateCalendarEvents(updatedEvents, setLoading);

    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes[eventIndex] = false;
      return newModes;
    });
  };

  const handleDeleteEvent = (eventIndex, itemIndex) => {
    setLoading(true);
    const updatedEvents = [...events];
    updatedEvents.splice(itemIndex, 1);
    setEvents(updatedEvents);
    updateCalendarEvents(updatedEvents, setLoading);

    setEditModes((prevModes) => {
      const newModes = [...prevModes];
      newModes.splice(eventIndex, 1);
      return newModes;
    });
    setEditedEventTitles((prevTitles) => {
      const newTitles = [...prevTitles];
      newTitles.splice(eventIndex, 1);
      return newTitles;
    });
    setEditedEventLists((prevLists) => {
      const newLists = [...prevLists];
      newLists.splice(eventIndex, 1);
      return newLists;
    });
  };

  // Render the events for the selected day
  const renderEvents = () => {
    if (!selectedDay) {
      return (
        <p style={{ color: darkMode && "white" }}>
          Seleccione un día para ver eventos.
        </p>
      );
    }

    const selectedEvents = events.filter((event) => event.date === selectedDay);

    const mydate = new Date(selectedDay);

    // Format the date as "January 12, 2024"
    const formattedDate = mydate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    });

    return (
      <div>
        <h2 style={{ color: darkMode && "white" }}>
          Eventos en {formattedDate}
        </h2>
        {selectedEvents.map((event, index) => (
          <div key={index} className="event-container">
            {editModes[index] ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextField
                  type="text"
                  multiline
                  value={editedEventTitles[index]}
                  onChange={(e) => {
                    const newTitles = [...editedEventTitles];
                    newTitles[index] = e.target.value;
                    setEditedEventTitles(newTitles);
                  }}
                />
                <span
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "20px",
                    margin: "20px 0px",
                    color: "#1976d2",
                    display: "flex",
                    alignItems: "center",
                  }}
                  className="event-title"
                >
                  Lista:{" "}
                  {
                    <>
                      <FormControl sx={{ minWidth: 240, mx: 1 }}>
                        <Select
                          defaultValue={editedEventLists[index]}
                          value={editedEventLists[index]}
                        >
                          <MenuItem disabled value="">
                            <em>Escoge Lista</em>
                          </MenuItem>
                          {listOptions.map((item) => (
                            <MenuItem
                              key={uuid()}
                              value={item.id}
                              onClick={() => {
                                const newLists = [...editedEventLists];
                                newLists[index] = item.id;
                                setEditedEventLists(newLists);
                              }}
                            >
                              {item.title ? item.title : item.date}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {editedEventLists[index] !== "" && (
                        <IconButton
                          onClick={() => {
                            const newLists = [...editedEventLists];
                            newLists[index] = "";
                            setEditedEventLists(newLists);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </>
                  }
                </span>
                <div>
                  <Button
                    variant="contained"
                    onClick={() =>
                      setEditModes((prevModes) => {
                        const newModes = [...prevModes];
                        newModes[index] = false;
                        return newModes;
                      })
                    }
                    sx={{ mx: 1, my: 2 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleSaveEvent(
                        index,
                        events.findIndex(
                          (item) =>
                            item.title === event.title &&
                            item.date === event.date
                        )
                      )
                    }
                    sx={{ mx: 1, my: 2 }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <p
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "20px",
                    color: darkMode && "white",
                  }}
                  className="event-title"
                >
                  {event.title}
                </p>
                {event.listID && (
                  <p
                    style={{
                      whiteSpace: "pre-line",
                      fontSize: "20px",
                      margin: "20px 0px",
                      color: "#1976d2",
                    }}
                    className="event-title"
                  >
                    Lista:{" "}
                    <a
                      style={{ textDecoration: "none" }}
                      href=""
                      onClick={(e) => goToOtherList(e, event.listID)}
                    >
                      {(() => {
                        const matchedOption = listOptions.find(
                          (obj) => obj.id === event.listID
                        );
                        return matchedOption?.title || matchedOption?.date;
                      })()}
                    </a>
                  </p>
                )}
                {userID !== "" && (
                  <div>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        handleEditEvent(
                          index,
                          events.findIndex(
                            (item) =>
                              item.title === event.title &&
                              item.date === event.date
                          )
                        )
                      }
                      sx={{ mx: 1, my: 2 }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        if (
                          confirm("¿Estás seguro de que quieres eliminar?") ==
                          true
                        ) {
                          handleDeleteEvent(
                            index,
                            events.findIndex(
                              (item) =>
                                item.title === event.title &&
                                item.date === event.date
                            )
                          );
                        }
                      }}
                      sx={{ mx: 1, my: 2 }}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className="calendar-container"
      style={{ backgroundColor: darkMode ? Colors.darkgrey : "white" }}
    >
      <center>
        <div className="calendar">
          <div className="calendar-header">
            <Button variant="outlined" onClick={() => changeMonth(-1)}>
              Previous
            </Button>
            <select onChange={selectMonth} value={currentMonth}>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
                <option key={month} value={month}>
                  {new Date(currentYear, month - 1, 1).toLocaleString(
                    "default",
                    {
                      month: "long",
                    }
                  )}
                </option>
              ))}
            </select>
            <select onChange={selectYear} value={currentYear}>
              {Array.from({ length: 41 }, (_, i) => currentYear - 20 + i).map(
                (year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                )
              )}
            </select>
            <Button variant="outlined" onClick={() => changeMonth(1)}>
              Next
            </Button>
          </div>
          <div className="calendar-days">{renderCalendarDays()}</div>
        </div>
        {isLoading && <CircularProgress />}
      </center>
      <div className="event-list-container">
        <div className="event-list">
          {renderEvents()}
          {selectedDay && userID !== "" && (
            <div
              className="add-event-container"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <TextField
                type="text"
                multiline
                value={newEvent}
                onChange={(e) => setNewEvent(e.target.value)}
                placeholder="Add new event..."
              />
              <span
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "20px",
                  margin: "20px 0px",
                  color: "#1976d2",
                  display: "flex",
                  alignItems: "center",
                }}
                className="event-title"
              >
                Lista:{" "}
                {
                  <>
                    <FormControl sx={{ minWidth: 240, mx: 1 }}>
                      <Select defaultValue="" value={newConnectedList}>
                        <MenuItem disabled value="">
                          <em>Escoge Lista</em>
                        </MenuItem>
                        {listOptions.map((item) => (
                          <MenuItem
                            key={uuid()}
                            value={item.id}
                            onClick={() => setNewConnectedList(item.id)}
                          >
                            {item.title ? item.title : item.date}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {newConnectedList !== "" && (
                      <IconButton onClick={() => setNewConnectedList("")}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </>
                }
              </span>
              <div>
                <Button
                  variant="contained"
                  onClick={handleAddEvent}
                  sx={{ mx: 1, my: 2 }}
                >
                  Add Event
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
