// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCK-PD9KOApoEotBBSIXYBWYCUd80gqWE4",
  authDomain: "lalista-9cb88.firebaseapp.com",
  databaseURL: "https://lalista-9cb88-default-rtdb.firebaseio.com",
  projectId: "lalista-9cb88",
  storageBucket: "lalista-9cb88.appspot.com",
  messagingSenderId: "138167745373",
  appId: "1:138167745373:web:c9d5f59d4166f2b36606b4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
