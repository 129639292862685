import React, { useState } from "react";
import { Modal, Fade, IconButton, useMediaQuery, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import { QRCode } from "react-qrcode-logo";
import logo from "../images/logo.png";

const QRShareModal = ({ data }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const screenWidth = window.innerWidth;

  return (
    <>
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Fade in={isModalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "auto",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: isSmallScreen ? "5px" : "10px",
              borderRadius: "8px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                onClick={() => setModalOpen(false)}
                aria-label="close"
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Box>

            <QRCode
              value={data}
              enableCORS={true}
              qrStyle="square"
              quietZone={24}
              logoPadding={5}
              logoImage={logo}
              logoWidth={isSmallScreen ? 75 : 150}
              size={isSmallScreen ? screenWidth * 0.85 : 500}
            />
          </Box>
        </Fade>
      </Modal>
      <IconButton
        onClick={() => setModalOpen(true)}
        sx={{ mx: 1 }}
        size="large"
      >
        <IosShareIcon sx={{ transform: "scale(1.5)" }} />
      </IconButton>
    </>
  );
};

export default QRShareModal;
