import React from "react";
import { useDarkMode } from "../context/DarkModeContext";

const LastModifiedByText = ({ email = "", time = "" }) => {
  const { darkMode } = useDarkMode();
  return (
    <p
      style={{
        margin: "20px 0px",
        textAlign: "center",
        color: darkMode ? "white" : "black",
      }}
    >
      Ultima modificacion por {email} el {time}.
    </p>
  );
};

export default LastModifiedByText;
