import React, { useEffect, useState, useContext } from "react";
import { useAuth } from "./AuthContext";
import { getDatabase, ref, get } from "firebase/database";

const DarkModeContext = React.createContext();

const DarkModeProvider = (props) => {
  const [darkMode, setDarkMode] = useState(false);
  const db = getDatabase();
  const { userID } = useAuth();

  useEffect(() => {
    if (userID !== "") {
      get(ref(db, "usuarios/" + userID + "/darkMode")).then((snapshot) => {
        if (snapshot.exists()) {
          setDarkMode(snapshot.val());
        }
      });
    } else {
      setDarkMode(false);
    }
  }, [userID]);

  return (
    <DarkModeContext.Provider
      value={{
        darkMode,
        setDarkMode,
      }}
    >
      {props.children}
    </DarkModeContext.Provider>
  );
};

export const useDarkMode = () => useContext(DarkModeContext);

export default DarkModeProvider;
