import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Switch,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  Divider,
} from "@mui/material";
import {
  Brightness4,
  Brightness7,
  Delete,
  Email,
  CardMembership,
  Translate,
} from "@mui/icons-material";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import { useDarkMode } from "../context/DarkModeContext";
import Colors from "../data/Colors";
import Languages from "../data/Languages";

const UserSettings = () => {
  const { darkMode, setDarkMode } = useDarkMode();

  const [user, setUser] = useState({
    name: "John Doe",
    email: "johndoe@example.com",
    subscriptionType: "Organization",
    createdAt: "2023-05-15",
    updatedAt: "2023-05-15",
    language: "english",
  });

  const [subscriptionDays, setSubscriptionDays] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });

  const [associatedRepositories, setAssociatedRepositories] = useState([
    { id: 1, name: "Repository 1" },
    { id: 2, name: "Repository 2" },
  ]);

  const [userRepositories, setUserRepositories] = useState([
    { id: 3, name: "User A", permissions: "Read" },
  ]);

  const handleChangePassword = () => {
    // TODO: prompt user to change password
  };

  const handleModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const handleSubscriptionDayChange = (day) => {
    setSubscriptionDays((prevDays) => ({
      ...prevDays,
      [day]: !prevDays[day],
    }));
  };

  const handleRemoveFromRepository = (repoId) => {
    setAssociatedRepositories((prevRepos) =>
      prevRepos.filter((repo) => repo.id !== repoId)
    );
  };

  const handlePermissionChange = (userId, newPermission) => {
    // TODO: add permissions to each user in the admin's repository.
  };

  const handleRemoveUserFromRepo = (userId) => {
    // TODO: remove user from list of users that have access to the repository.
  };

  const handleSaveChanges = () => {
    // TODO: save changes to the database; utilize any functions to save changes.
    alert("¡Los datos han sido añadidos!");
  };

  return (
    <Box
      sx={{
        backgroundColor: darkMode ? Colors.darkgrey : "white",
        color: darkMode ? "white" : "black",
        minHeight: "100vh",
        padding: "20px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ maxWidth: 900, width: "100%", padding: 3 }}>
          <CardContent>
            <Typography
              variant="h3"
              component="div"
              gutterBottom
              sx={{
                fontWeight: 500,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              {user.name}
            </Typography>

            <Divider sx={{ my: 2 }} />

            <Box display="flex" alignItems="center" mb={1}>
              <Email color="action" sx={{ mr: 1 }} />
              <Typography variant="body1" component="span">
                <strong>Email:</strong> {user.email}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={1}>
              <CardMembership color="action" sx={{ mr: 1 }} />
              <Typography variant="body1" component="span">
                <strong>Subscription Type:</strong> {user.subscriptionType}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb={1}>
              <Translate color="action" sx={{ mr: 1 }} />
              <Typography
                variant="body1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
                component="span"
              >
                Language:{" "}
                {
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select defaultValue={user?.language || "english"}>
                      {Languages.map((item) => (
                        <MenuItem value={item}>
                          {item.charAt(0).toUpperCase() + item.slice(1)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                }
              </Typography>
            </Box>

            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ fontStyle: "italic", mt: 1 }}
            >
              <strong>First Created:</strong> {user.createdAt}
            </Typography>

            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ fontStyle: "italic", mt: 1 }}
            >
              <strong>Last Updated:</strong> {user.updatedAt}
            </Typography>

            <Divider sx={{ my: 2 }} />

            <FormControlLabel
              control={
                <Switch
                  checked={darkMode}
                  onChange={handleModeToggle}
                  color="primary"
                />
              }
              label={
                darkMode ? (
                  <Brightness7 color="action" />
                ) : (
                  <Brightness4 color="action" />
                )
              }
              sx={{ mt: 2, justifyContent: "center", display: "flex" }}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button variant="contained" color="primary" sx={{ mt: 3 }}>
                Change Subscription
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 3 }}
                onClick={handleChangePassword}
              >
                Change Password
              </Button>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ mt: 3 }}>
              <Typography variant="h5">Associations</Typography>
              <List>
                {associatedRepositories.map((repo) => (
                  <ListItem key={repo.id}>
                    <ListItemText primary={repo.name} />
                    <Button
                      onClick={() => handlePermissionChange(user.id, "Write")}
                    >
                      Set As Current
                    </Button>
                    <IconButton
                      onClick={() => handleRemoveFromRepository(repo.id)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Box>

            <Divider sx={{ my: 2 }} />

            {user.subscriptionType === "Organization" && (
              <>
                <Box sx={{ mt: 3 }}>
                  <Typography variant="h5">Manage User Access</Typography>
                  <List>
                    {userRepositories.map((user) => (
                      <ListItem key={user.id}>
                        <ListItemText primary={`${user.name}`} />
                        <Button
                          onClick={() =>
                            handlePermissionChange(user.id, "Write")
                          }
                        >
                          Set Permissions
                        </Button>
                        <IconButton
                          onClick={() => handleRemoveUserFromRepo(user.id)}
                        >
                          <Delete />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Typography variant="h5" sx={{ mb: 2 }}>
                  Automatic List Generator
                </Typography>
                <FormControlLabel
                  label={`Increase each song's \"times played\" amount on next list generation?`}
                  control={<Checkbox />}
                />
                <FormControlLabel
                  label={`Reset vote counts on next list generation?`}
                  control={<Checkbox />}
                />

                <FormGroup>
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    sx={{ mt: 2 }}
                  >
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Day
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Time
                      </Typography>
                    </Grid>
                  </Grid>
                  {Object.keys(subscriptionDays).map((day) => (
                    <Grid
                      container
                      key={day}
                      spacing={1}
                      alignItems="center"
                      sx={{ mb: 2 }}
                    >
                      <Grid item xs={6}>
                        <FormControlLabel
                          label={day.charAt(0).toUpperCase() + day.slice(1)}
                          control={
                            <Checkbox
                              checked={subscriptionDays[day]}
                              onChange={() => handleSubscriptionDayChange(day)}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MobileTimePicker
                          views={["hours"]}
                          sx={{ width: "75%" }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </FormGroup>

                <Divider sx={{ my: 2 }} />
              </>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChanges}
              sx={{
                mt: 1,
                borderRadius: 2,
                px: 3,
              }}
            >
              Save Changes
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default UserSettings;
