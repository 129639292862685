import React, { useEffect, useState, useRef } from "react";
import ChordSheetJS from "chordsheetjs";
import {
  Box,
  CircularProgress,
  Button,
  useMediaQuery,
  TextareaAutosize,
  IconButton,
} from "@mui/material";
import { Buffer } from "buffer";
import RefreshIcon from "@mui/icons-material/Refresh";

import { useAuth } from "../context/AuthContext";
import { useDarkMode } from "../context/DarkModeContext";

import uuid from "react-uuid";

//Firebase imports
import { db } from "../firebase";
import { ref, child, get } from "firebase/database";
import {
  updateDBList,
  updateDBListSongNotes,
  updateDBListSongMemos,
} from "../utils/DBFunctions";

//Component imports
import DraggableSongList from "../components/DraggableSongList";
import ModalRepo from "../components/ModalRepo";
import SongList from "../components/SongList";
import LastModifiedByText from "../components/LastModifiedByText";
import NotesModal from "../components/NotesModal";
import MemoModal from "../components/MemoModal";
import StoreListModal from "../components/StoreListModal";
import ScrollButton from "../components/ScrollButton";
import FontSizeAdjuster from "../components/FontSizeAdjuster";
import Colors from "../data/Colors";

const Home = () => {
  const [songs, setSongs] = useState([]);
  const [notes, setNotes] = useState([]);
  const [memos, setMemos] = useState([]);
  const [oldSongsArr, setOldSongsArr] = useState([]);
  const [document, setDocument] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [currKey, setCurrKey] = useState("C");
  const [keyPref, setKeyPref] = useState("C");
  const [isLoading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [currListKeyID, setCurrListKeyID] = useState("");
  const [open, setOpen] = useState(false);
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [notesVisible, setNotesVisible] = useState(false);
  const [memoVisible, setMemoVisible] = useState(false);
  const [chordSheet, setChordSheet] = useState("");
  const [chordSheetFontSize, setChordSheetFontSize] = useState("14px");
  const chordSheetRef = useRef();
  const songListRef = useRef();
  const videoRef = useRef();

  const [LastAuthorEmail, setLastAuthorEmail] = useState("N/A");
  const [LastAuthorTime, setLastAuthorTime] = useState("N/A");

  const { userID, auth } = useAuth();
  const { darkMode } = useDarkMode();

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:601px) and (max-width:900px)"
  );

  const [editMode, setEditMode] = useState(false);

  const toggleNotes = () => setNotesVisible(!notesVisible);
  const toggleMemo = () => setMemoVisible(!memoVisible);

  let sundayDt = new Date();
  sundayDt.setDate(sundayDt.getDate() + ((7 - sundayDt.getDay()) % 7));

  sundayDt = sundayDt
    .toLocaleDateString("es-ES", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    })
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

  if (sundayDt !== localStorage.getItem("date")) {
    localStorage.clear();
    localStorage.setItem("date", sundayDt);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dbRef = ref(db);
        const snapshot = await get(child(dbRef, "ordenDeLaLista"));
        if (snapshot.exists()) {
          const songsData = await Promise.all(
            snapshot.val().map(async (item) => {
              const itemSnapshot = await get(
                child(dbRef, `articulos/${item.id}`)
              );
              if (itemSnapshot.exists()) {
                const songObj = { ...itemSnapshot.val() };
                songObj.keyPref = item.keyPref;
                songObj.listKeyID = uuid();
                return songObj;
              }
              throw new Error("No data available.");
            })
          );

          setSongs(songsData);
          setOldSongsArr([...songsData]);
          const notesData = snapshot.val().map((item) => ({
            id: item.id,
            notes: item.notes ?? "",
          }));
          setNotes([...notesData]);
          const firstSong = songsData[0];
          if (firstSong) {
            // setting first document
            try {
              const response = await fetch(firstSong.file);
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }

              const textContent = await response.text();
              const buff = Buffer.from(textContent, "utf-8");
              const base64Content = buff.toString("base64");

              setDocument(base64Content);
              setLoading(false);
            } catch (error) {
              console.error("Error fetching or saving the file:", error);
            }

            setCurrKey(firstSong.key);
            setKeyPref(firstSong.keyPref);
            setVideoLink(firstSong.video);
          }
        } else {
          console.log("No data available.");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };

    const fetchLastModifiedData = async () => {
      try {
        const dbRef = ref(db);
        const snapshot = await get(child(dbRef, "LastModifiedData"));
        if (snapshot.exists()) {
          setLastAuthorEmail(snapshot.val().currListLastAuthor);
          setLastAuthorTime(snapshot.val().currListLastTime);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    fetchLastModifiedData();

    setChordSheetFontSize(
      isSmallScreen ? "14px" : isMediumScreen ? "20px" : "26px"
    );
  }, []);

  useEffect(() => {
    const fetchMemos = async () => {
      try {
        const dbRef = ref(db);
        const snapshot = await get(child(dbRef, "ordenDeLaLista"));
        if (snapshot.exists()) {
          const memosData = await Promise.all(
            snapshot.val().map(async (item) => {
              const memoText = await get(
                child(dbRef, `usuarios/${userID}/memos/${item.id}`)
              );
              return { [item.id]: memoText.val() ? memoText.val() : "" };
            })
          );

          setMemos(memosData);
        } else {
          console.log("No data available.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    userID && fetchMemos();
  }, [userID]);

  useEffect(() => {
    if (document !== "") {
      try {
        const buff = Buffer.from(document, "base64");
        const text = buff.toString("utf-8");
        const parser = new ChordSheetJS.UltimateGuitarParser();
        const chordSheet = parser.parse(text);
        const currChordSheet = chordSheet.setKey(currKey);
        const newSheet = currChordSheet.changeKey(keyPref);
        const formatter = new ChordSheetJS.TextFormatter();
        const outputText = formatter.format(newSheet);
        setLoading(false);
        setChordSheet(outputText);
      } catch (error) {
        setLoading(false);
        console.error("Error parsing chord sheet:", error);
      }
    }
  }, [document, currListKeyID]);

  const handleSave = async () => {
    try {
      setLoading(true);
      updateDBList(
        songs,
        (choice) => setLoading(choice),
        auth.currentUser.email
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        backgroundColor: darkMode ? Colors.darkgrey : "white",
        minHeight: "100vh",
      }}
    >
      <ModalRepo
        sendItemCB={(item) => {
          const songObj = { ...item };
          songObj.keyPref = item.key;
          songObj.notes = "";
          songObj.listKeyID = uuid();
          setSongs((current) => [...current, songObj]);
        }}
        open={open}
        setOpen={setOpen}
      />
      <StoreListModal
        data={{ songs: songs, email: LastAuthorEmail }}
        open={storeModalOpen}
        onClose={() => setStoreModalOpen(false)}
        isLoading={isLoading}
        setLoading={setLoading}
      />
      <ScrollButton
        targetRef={songListRef}
        conditional={"GREATER"}
        corner={"left"}
        breakpoint={260}
      />
      <ScrollButton
        targetRef={videoVisible ? videoRef : chordSheetRef}
        conditional={"LESS"}
        corner={"right"}
        breakpoint={260}
      />
      <IconButton
        onClick={() => location.reload()}
        sx={{ mt: 1, position: "absolute", top: 70, left: 15 }}
        size="medium"
      >
        <RefreshIcon sx={{ transform: "scale(1.5)" }} />
      </IconButton>
      {!editMode && (
        <Box
          ref={songListRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 2,
            flexDirection: isSmallScreen ? "column" : "row", // Initially set to column layout
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              onClick={() => setVideoVisible(false)}
              sx={{
                mx: 2,
                backgroundColor: !videoVisible ? "green" : "grey",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
            >
              Acordes
            </Button>
            <Button
              variant="contained"
              onClick={() => setVideoVisible(true)}
              sx={{
                mx: 2,
                backgroundColor: videoVisible ? "green" : "grey",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
            >
              Video
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: isSmallScreen ? 2 : 0,
            }}
          >
            <Button
              variant="contained"
              onClick={toggleNotes}
              sx={{
                mx: 2,
              }}
            >
              Notas
            </Button>

            {userID != "" && (
              <Button
                variant="contained"
                onClick={toggleMemo}
                sx={{
                  mx: 2,
                  backgroundColor: "#EE6352",
                  "&:hover": {
                    backgroundColor: "#C65445",
                  },
                }}
              >
                Memo
              </Button>
            )}
          </Box>
        </Box>
      )}

      <NotesModal
        item={notes.find((obj) => obj.id === oldSongsArr[selectedIndex].id)}
        isEditable={userID !== ""}
        isOpen={notesVisible}
        onClose={toggleNotes}
        currListItemID={"Home"}
        saveNotesCB={(item) => {
          const storedNotes = notes.find(
            (obj) => obj.id === songs[selectedIndex].id
          );

          if (storedNotes === undefined) {
            setNotes((current) => [
              ...current,
              { id: songs[selectedIndex].id, notes: item },
            ]);
          } else {
            storedNotes.notes = item;
          }

          try {
            updateDBListSongNotes(songs[selectedIndex].id, item);
            alert("¡Los datos han sido añadidos!");
          } catch (error) {
            console.log(error);
          }

          toggleNotes();
        }}
      />

      <MemoModal
        item={
          memos?.find((obj) => oldSongsArr[selectedIndex]?.id in obj)?.[
            oldSongsArr[selectedIndex]?.id
          ] ?? ""
        }
        isOpen={memoVisible}
        onClose={toggleMemo}
        currListItemID={"Home"}
        saveMemoCB={(item) => {
          const resultObject = memos.find(
            (obj) => songs[selectedIndex].id in obj
          );
          const indexToUpdate = memos.findIndex(
            (obj) => songs[selectedIndex].id in obj
          );
          const storedMemos = resultObject
            ? resultObject[songs[selectedIndex].id]
            : null;

          if (storedMemos === null) {
            const newObj = { [songs[selectedIndex].id]: item };
            setMemos((prevArray) => [...prevArray, newObj]);
          } else {
            memos[indexToUpdate][songs[selectedIndex].id] = item;
          }

          try {
            updateDBListSongMemos(songs[selectedIndex].id, item, userID);
            alert("¡Los datos han sido añadidos!");
          } catch (error) {
            console.log(error);
          }

          toggleMemo();
        }}
      />

      {!editMode && (
        <SongList
          setLoading={setLoading}
          songs={songs}
          setDocument={setDocument}
          setCurrKey={setCurrKey}
          setKeyPref={setKeyPref}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          setCurrListKeyID={setCurrListKeyID}
          setVideoLink={setVideoLink}
        />
      )}
      {editMode && userID !== "" && (
        <Box sx={{ display: "flex", justifyContent: "center", py: 5 }}>
          <Button variant="contained" onClick={() => setOpen(true)}>
            Add
          </Button>
        </Box>
      )}
      {editMode && userID !== "" && (
        <DraggableSongList songs={songs} setSongs={setSongs} />
      )}
      {!editMode && userID !== "" && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <Button variant="contained" onClick={() => setEditMode(true)}>
            Edit
          </Button>
          <Button
            variant="contained"
            sx={{
              mx: 2,
              backgroundColor: "GhostWhite",
              "&:hover": {
                backgroundColor: "Gainsboro",
              },
              color: "gray",
            }}
            onClick={() => {
              setStoreModalOpen(true);
            }}
          >
            Store ➜
          </Button>
        </Box>
      )}
      {editMode && userID !== "" && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: 360,
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "grey",
                "&:hover": {
                  backgroundColor: "dimgrey",
                },
              }}
              onClick={() => {
                setSongs(oldSongsArr);
                setEditMode(false);
              }}
            >
              Cancel
            </Button>
            {editMode && isLoading && <CircularProgress />}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              disabled={songs.length === 0}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}

      {userID !== "" && (
        <LastModifiedByText email={LastAuthorEmail} time={LastAuthorTime} />
      )}

      {!editMode && (
        <FontSizeAdjuster
          initialFontSize={parseInt(
            (isSmallScreen
              ? "14px"
              : isMediumScreen
              ? "20px"
              : "26px"
            ).substring(0, 2)
          )}
          currFontSize={parseInt(chordSheetFontSize.substring(0, 2))}
          setFontSize={setChordSheetFontSize}
        />
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mt: 3,
          pb: 3,
        }}
      >
        {!editMode && isLoading && (
          <CircularProgress style={{ marginBottom: "20px" }} />
        )}
        {!editMode && !videoVisible && (
          <TextareaAutosize
            ref={chordSheetRef}
            placeholder="Cargando..."
            readOnly
            value={chordSheet}
            onChange={(event) => {
              setChordSheet(event.target.value);
            }}
            style={{
              width: "90vw",
              maxWidth: isSmallScreen
                ? "600px"
                : isMediumScreen
                ? "750px"
                : "900px",
              padding: "12px",
              marginBottom: 50,
              borderRadius: "6px",
              resize: "none",
              fontSize: chordSheetFontSize,
              fontFamily: "Roboto Mono, monospace",
              backgroundColor: darkMode ? Colors.darkgrey : "white",
              color: darkMode ? "white" : "black",
            }}
          />
        )}
        {!editMode && videoVisible && (
          <Box ref={videoRef} sx={{ maxWidth: { xs: 360, md: 720 }, mb: 10 }}>
            <iframe
              onLoad={() => setLoading(false)}
              onError={() => setLoading(false)}
              style={{ width: "100%", height: "360px" }}
              src={videoLink}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default Home;
