import {
  ref as storageRef,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  get,
  set,
  update,
  remove,
  push,
  child,
  ref as databaseRef,
} from "firebase/database";
import { storage, db } from "../firebase";
import uuid from "react-uuid";

let formatter = new Intl.DateTimeFormat([], {
  timeZone: "America/Los_Angeles",
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});

const currentTime = formatter.format(new Date());

export const createFile = (bits, name, options) => {
  const myBlob = new Blob(bits, options || {});
  myBlob.lastModified = new Date();
  myBlob.name = name;
  return myBlob;
};

export async function addToDB(item, file, loadCB) {
  if (!file || !item.name) return;

  loadCB(true);

  const fileUUID = uuid();

  const newFile = createFile([file], fileUUID, {
    type: "text/plain",
  });

  const sRef = storageRef(storage, `${newFile.name}`);

  try {
    await uploadBytes(sRef, newFile);
    const url = await getDownloadURL(sRef);

    const newSongRef = push(databaseRef(db, "articulos"));
    await set(newSongRef, {
      id: newSongRef.key,
      file: url,
      key: item.key,
      name: item.name,
      author: item.author,
      type: item.type,
      speed: item.speed,
      video: item.video,
      performNum: 0,
    });

    await update(databaseRef(db, "LastModifiedData"), {
      repoLastAuthor: item.repoLastAuthor,
      repoLastTime: currentTime,
    });

    loadCB(false);
    alert("¡Los datos han sido añadidos!");
  } catch (error) {
    console.log(error);
    loadCB(false);
  }

  window.history.back();
}

export async function updateInDB(item, file, loadCB) {
  if (!item.name) return;

  loadCB(true);

  if (!file) {
    try {
      await update(databaseRef(db, `articulos/${item.id}`), {
        id: item.id,
        key: item.key,
        name: item.name,
        author: item.author,
        type: item.type,
        speed: item.speed,
        video: item.video,
        performNum: item.performNum,
      });

      await update(databaseRef(db, "LastModifiedData"), {
        repoLastAuthor: item.repoLastAuthor,
        repoLastTime: currentTime,
      });

      loadCB(false);
      alert("¡Los datos han sido actualizados!");
    } catch (error) {
      console.log(error);
      loadCB(false);
    }
  } else {
    const fileUUID = uuid();

    const newFile = createFile([file], fileUUID, {
      type: "text/plain",
    });

    const oldSRef = storageRef(storage, item.file);

    try {
      await deleteObject(oldSRef);
    } catch (error) {
      console.log(error);
    }

    const newSRef = storageRef(storage, `${newFile.name}`);

    try {
      await uploadBytes(newSRef, newFile);
      const url = await getDownloadURL(newSRef);

      await update(databaseRef(db, `articulos/${item.id}`), {
        id: item.id,
        file: url,
        key: item.key,
        name: item.name,
        author: item.author,
        type: item.type,
        speed: item.speed,
        video: item.video,
        performNum: item.performNum,
      });

      loadCB(false);

      await update(databaseRef(db, "LastModifiedData"), {
        repoLastAuthor: item.repoLastAuthor,
        repoLastTime: currentTime,
      });

      alert("¡Los datos han sido actualizados!");
    } catch (error) {
      console.log(error);
    }
  }

  window.history.back();
}

export async function deleteFromDB(item, loadCB, repoLastAuthor) {
  loadCB();

  const sRef = storageRef(storage, item.file);

  try {
    await deleteObject(sRef);
    await remove(databaseRef(db, `articulos/${item.id}`));

    await update(databaseRef(db, "LastModifiedData"), {
      repoLastAuthor: repoLastAuthor,
      repoLastTime: currentTime,
    });

    loadCB(false);
    alert("¡Los datos han sido eliminados!");
  } catch (error) {
    console.log(error);
    loadCB(false);
  }

  window.history.back();
}

/*
###############################################
## CODE FOR OTHER LISTS FUNCITONS START HERE ##
###############################################
*/

export const addOtherListItem = (item, loadCB) => {
  loadCB(true);

  let cleanList = {};
  for (let index = 0; index < item.songs.length; index++) {
    const song = item.songs[index];
    cleanList[index] = {
      id: song.id,
      keyPref: song.keyPref,
      notes: song.notes ?? "",
    };
  }

  let newListRef = push(databaseRef(db, "coleccionDeListas"));
  let { key: id } = newListRef;
  set(newListRef, {
    id,
    date: item.date,
    title: item.title,
    songs: cleanList,
  })
    .then(() =>
      update(databaseRef(db, "LastModifiedData"), {
        otherListLastAuthor: item.otherListLastAuthor,
        otherListLastTime: currentTime,
      })
    )
    .then(() => {
      loadCB(false);
      alert("¡Los datos han sido añadidos!");
      location.reload();
    })
    .catch((error) => {
      loadCB(false);
      console.log(error);
    });
};

export const updateOtherListItem = (
  { id, date, title, songs, otherListLastAuthor },
  loadCB
) => {
  loadCB(true);

  const cleanList = songs.reduce((result, currentItem, index) => {
    return {
      ...result,
      [index]: {
        id: currentItem.id,
        keyPref: currentItem.keyPref,
        notes: currentItem.notes ?? "",
      },
    };
  }, {});

  Promise.all([
    update(databaseRef(db, "coleccionDeListas/" + id), {
      id,
      date,
      title,
      songs: cleanList,
    }),
    update(databaseRef(db, "LastModifiedData"), {
      otherListLastAuthor: otherListLastAuthor,
      otherListLastTime: currentTime,
    }),
  ])
    .then(() => {
      loadCB(false);
      alert("¡Los datos han sido actualizados!");
      location.reload();
    })
    .catch((error) => {
      loadCB(false);
      console.log(error);
    });
};

export const deleteOtherListItem = async (
  itemID,
  loadCB,
  otherListLastAuthor
) => {
  loadCB(true);

  try {
    await remove(databaseRef(db, `coleccionDeListas/${itemID}`));
    await update(databaseRef(db, "LastModifiedData"), {
      otherListLastAuthor: otherListLastAuthor,
      otherListLastTime: currentTime,
    });

    loadCB(false);
    alert("¡Los datos han sido eliminados!");
    location.reload();
  } catch (error) {
    loadCB(false);
    console.log(error);
  }
};

/*
##################################################
## CODE FOR OTHER DATABASE FUNCITONS START HERE ##
##################################################
*/

export const modifyVoteDB = (uuid, songName, songID, songAuthor, loadCB) => {
  loadCB(true);

  const voteRef = databaseRef(db);
  const voteChildRef = child(voteRef, `votos/${uuid}`);

  get(voteChildRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        loadCB(false);
        alert("¡Este dispositivo ya ha votado!");
      } else {
        const newVoteData = {
          id: uuid,
          song: songName,
          songID: songID,
          songAuthor: songAuthor,
        };

        set(voteChildRef, newVoteData)
          .then(() => {
            loadCB(false);
            alert("¡Gracias por votar!");
            location.reload();
          })
          .catch((error) => {
            loadCB(false);
            console.log(error);
          });
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const updateDBList = (dirtyList, loadCB, currListLastAuthor) => {
  loadCB(true);

  const cleanList = {};
  dirtyList.forEach((item, index) => {
    cleanList[index] = {
      id: item.id,
      keyPref: item.keyPref,
      notes: item.notes ?? "",
    };
  });

  set(databaseRef(db, "ordenDeLaLista"), cleanList)
    .then(() =>
      update(databaseRef(db, "LastModifiedData"), {
        currListLastAuthor: currListLastAuthor,
        currListLastTime: currentTime,
      })
    )
    .then(() => {
      loadCB(false);
      alert("¡Los datos han sido actualizados!");
      location.reload();
    })
    .catch((error) => {
      loadCB(false);
      console.log(error);
    });
};

export const updateDBListSongNotes = async (id, notes) => {
  const dbRef = databaseRef(db);
  const listRef = child(dbRef, `ordenDeLaLista`);

  try {
    get(listRef)
      .then((snapshot) => {
        // Process the data
        const data = snapshot.val();

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const item = data[key];
            if (item.id === id) {
              // Update the data in Firebase
              update(databaseRef(db, `ordenDeLaLista/${key}`), {
                notes: notes ?? "",
              });

              // Stop the loop once the item is found and updated
              break;
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const updateDBListSongMemos = async (id, notes, userID) => {
  // Update the object with the dynamic key
  update(databaseRef(db, `usuarios/${userID}/memos`), {
    [id]: notes,
  }).catch((error) => {
    console.error("Error updating object:", error);
  });
};

export const updateOtherListSongNotes = async (listID, songID, notes) => {
  const dbRef = databaseRef(db);
  const listRef = child(dbRef, `coleccionDeListas/${listID}/songs`);

  try {
    get(listRef)
      .then((snapshot) => {
        // Process the data
        const data = snapshot.val();

        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            const item = data[key];
            if (item.id === songID) {
              // Update the data in Firebase
              update(
                databaseRef(db, `coleccionDeListas/${listID}/songs/${key}`),
                {
                  notes: notes ?? "",
                }
              );

              // Stop the loop once the item is found and updated
              break;
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  } catch (error) {
    console.log(error);
  }
};

export const updateCalendarEvents = async (array, loadCB) => {
  const dbRef = databaseRef(db);
  const calendarEventsRef = child(dbRef, `calendarEvents`);

  const cleanList = {};

  if (array.length !== 0) {
    array.forEach((item, index) => {
      cleanList[index] = {
        date: item.date,
        title: item.title,
        listID: item.listID,
      };
    });
  }

  try {
    await set(calendarEventsRef, cleanList);
    loadCB(false);
    alert("¡Los datos han sido añadidos!");
  } catch (error) {
    console.log(error);
    loadCB(false);
  }
};
