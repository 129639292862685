import React, { memo } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import { useDarkMode } from "../context/DarkModeContext";

const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DraggableSongList = memo(({ songs, setSongs }) => {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    setSongs(reorder(songs, result.source.index, result.destination.index));
  };

  const removeFromArr = (value) => {
    setSongs(songs.filter((item) => item.listKeyID !== value));
  };

  const handleKeyPrefChange = (event, id) => {
    const newSongs = songs.map((song) =>
      song.listKeyID === id ? { ...song, keyPref: event.target.value } : song
    );
    setSongs(newSongs);
  };

  const { darkMode } = useDarkMode();

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          width: "100%",
          maxWidth: 360,
          border: 1,
          borderColor: "lightgrey",
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <List
                disablePadding
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {songs.map((item, index) => (
                  <Draggable
                    key={item.listKeyID}
                    draggableId={item.listKeyID}
                    index={index}
                  >
                    {(provided) => (
                      <React.Fragment key={item.listKeyID}>
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          secondaryAction={
                            <>
                              <Select
                                size="small"
                                value={item.keyPref ?? "C"}
                                onChange={(event) =>
                                  handleKeyPrefChange(event, item.listKeyID)
                                }
                              >
                                {[
                                  "C",
                                  "C#",
                                  "D",
                                  "D#",
                                  "E",
                                  "F",
                                  "F#",
                                  "G",
                                  "G#",
                                  "A",
                                  "A#",
                                  "B",
                                ].map((key) => (
                                  <MenuItem key={key} value={key}>
                                    {key}
                                  </MenuItem>
                                ))}
                              </Select>
                              <IconButton
                                onClick={() => removeFromArr(item.listKeyID)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          }
                        >
                          <ListItemText
                            style={{ color: darkMode ? "white" : "black" }}
                            primary={
                              item.name.length < 25
                                ? item.name
                                : `${item.name.slice(0, 25)}...`
                            }
                          />
                        </ListItem>
                        {index !== songs.length - 1 && <Divider />}
                      </React.Fragment>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
});

export default DraggableSongList;
