import React, { useEffect, useState, useContext } from "react";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { DeviceUUID } from "device-uuid";

const AuthContext = React.createContext();

const AuthProvider = (props) => {
  const auth = getAuth();
  const [userID, setUserID] = useState("");
  const [isLoading, setLoading] = useState(false);
  const db = getDatabase();
  const connectedRef = ref(db, ".info/connected");
  const uuid = new DeviceUUID().get();

  function CheckInternet() {
    let result = false;
    onValue(
      connectedRef,
      (snap) => {
        if (snap.val() === true) {
          result = true;
        }
      },
      { onlyOnce: true }
    );
    return result;
  }

  function ErrorMessages(code) {
    let message = "";

    switch (code) {
      case "auth/user-not-found":
        message = "No user found with those credentials.";
        break;
      case "auth/wrong-password":
      case "auth/internal-error":
      case "auth/invalid-email":
        message = "Wrong email or password.";
        break;
      default:
        message = "An unknown error occurred.";
        break;
    }

    return message;
  }

  useEffect(() => {
    // Listen for authentication state to change.
    return onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        setUserID(user.uid);
        // Save the new device information to Firebase Realtime Database
        set(ref(db, `usuarios/${user.uid}/activeDevice`), uuid);
      } else {
        setUserID("");
      }
    });
  }, []);

  useEffect(() => {
    if (userID) {
      // Listen to changes in the active device field in Realtime Database
      const userDeviceRef = ref(db, "usuarios/" + userID + "/activeDevice");

      const unsubscribe = onValue(userDeviceRef, (snapshot) => {
        const activeDevice = snapshot.val();

        if (activeDevice !== uuid) {
          // If the active device changes and is not the current one, sign the user out
          auth.signOut();
        }
      });

      return () => unsubscribe();
    }
  }, [userID]);

  const dispatchUserEvent = (action, payload, callback) => {
    switch (action) {
      case "LOGIN":
        setLoading(true);
        signInWithEmailAndPassword(auth, payload.email, payload.password)
          .then(() => {
            setLoading(false);
            callback();
          })
          .catch((err) => {
            setLoading(false);
            alert(ErrorMessages(err.code));
          });
        break;
      case "RESETPASS":
        setLoading(true);
        sendPasswordResetEmail(auth, payload.email)
          .then(() => {
            setLoading(false);
            alert(
              "¡Se envió el correo electrónico de restablecimiento de contraseña!"
            );
            callback();
          })
          .catch((err) => {
            setLoading(false);
            alert(err.message);
          });
      default:
        break;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userID,
        auth,
        dispatchUserEvent,
        isLoading,
        setLoading,
        CheckInternet,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
