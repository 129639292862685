import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Container,
  Avatar,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { getDatabase, ref, set } from "firebase/database";

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const { auth, userID } = useAuth();
  const navigate = useNavigate();
  const db = getDatabase();

  const pages = [
    { text: "Lista Actual", path: "/" },
    { text: "Otras Listas", path: "/otherlists" },
    { text: "Calendario", path: "/calendar" },
    { text: "Repertorio", path: "/repository" },
    { text: "Generador", path: "/generator" },
    {
      text: userID !== "" ? "Votos" : "Votar",
      path: userID !== "" ? "/votecount" : "/vote",
    },
  ];

  const settings = [
    {
      key: "usersettings",
      text: userID !== "" ? "Ajustes" : "",
      action:
        userID !== ""
          ? () => navigate("/usersettings")
          : () => console.log("Login required."),
    },
    {
      key: "session",
      text: userID !== "" ? "Cerrar Sesión" : "Iniciar Sesión",
      action:
        userID !== "" ? () => handleLogout() : () => navigate("/adminlogin"),
    },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = (action) => {
    action();
    handleCloseNavMenu();
    handleCloseUserMenu();
  };

  const handleLogout = () => {
    set(ref(db, `usuarios/${userID}/activeDevice`), null);
    auth.signOut();
    navigate("/adminlogin");
  };

  return (
    <AppBar style={{ backgroundColor: "#1976d2" }} position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
              userSelect: "none",
            }}
          >
            LA LISTA
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.text}
                  onClick={() => {
                    navigate(page.path);
                  }}
                >
                  <Typography textAlign="center">{page.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
            <Typography
              variant="h5"
              noWrap
              sx={{
                userSelect: "none",
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              LA LISTA
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.text}
                onClick={() => {
                  navigate(page.path);
                }}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {page.text}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar sx={{ bgcolor: "white" }}>
                <ManageAccountsIcon
                  sx={{ color: userID !== "" ? "green" : "grey" }}
                />
              </Avatar>
            </IconButton>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(
                (setting) =>
                  setting.text !== "" && (
                    <MenuItem
                      key={setting.key}
                      onClick={() => handleMenuClick(setting.action)}
                    >
                      <Typography textAlign="center">{setting.text}</Typography>
                    </MenuItem>
                  )
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
