import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, Box } from "@mui/material";

import { db } from "../firebase";
import { get, ref as databaseRef } from "firebase/database";

import { useDarkMode } from "../context/DarkModeContext";
import Colors from "../data/Colors";

const countOccurrences = (textArray) => {
  const occurrenceMap = {};

  textArray.forEach(({ songID, song, songAuthor }) => {
    if (!occurrenceMap[songID]) {
      // Initialize entry for the songID with song details and first occurrence
      occurrenceMap[songID] = { song, songAuthor, occurrence: 1 };
    } else {
      // Increment the occurrence count for the existing songID
      occurrenceMap[songID].occurrence += 1;
    }
  });

  // Convert the map to an array of objects
  return Object.keys(occurrenceMap).map((songID) => ({
    id: songID,
    song: occurrenceMap[songID].song,
    songAuthor: occurrenceMap[songID].songAuthor,
    occurrence: occurrenceMap[songID].occurrence,
  }));
};

export default function VoteCount() {
  const [results, setResults] = useState([]);
  const { darkMode } = useDarkMode();

  useEffect(() => {
    const voteRef = databaseRef(db, "votos");
    get(voteRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const arr = Object.values(snapshot.val());
          const voteCounts = countOccurrences(arr);
          setResults(voteCounts);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div
      style={{
        backgroundColor: darkMode ? Colors.darkgrey : "white",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            border: darkMode ? "2px solid #FFF" : "2px solid #000",
            width: { xs: 300, md: 500 },
            mt: 5,
          }}
        >
          <List>
            {results.map((item) => {
              return (
                <ListItem
                  style={{
                    backgroundColor: darkMode ? Colors.darkgrey : "white",
                  }}
                  key={item.id}
                  secondaryAction={
                    <ListItemText
                      style={{ color: darkMode && "white" }}
                      edge="end"
                      primary={item.occurrence}
                    />
                  }
                >
                  <ListItemText
                    style={{ color: darkMode && "white" }}
                    primary={item.song}
                    secondary={item.songAuthor}
                  />
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    </div>
  );
}
