import React, { useEffect, useState } from "react";
import ChordSheetJS from "chordsheetjs";
import PrintChordSheet from "../components/PrintChordSheet";
import { useLocation, useNavigate } from "react-router-dom";

//Material UI imports
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
  Select,
  MenuItem,
  useMediaQuery,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

//Component imports
import { useAuth } from "../context/AuthContext";
import { useDarkMode } from "../context/DarkModeContext";

//Function imports
import { addToDB, updateInDB, deleteFromDB } from "../utils/DBFunctions";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import QRScanModal from "../components/QRScanModal";
import QRShareModal from "../components/QRShareModal";
import FontSizeAdjuster from "../components/FontSizeAdjuster";
import Colors from "../data/Colors";

export default function SongDetail() {
  const chordSheetTemplate = `[Intro]\nAm C F G  x2\n\n[Verse]\n  Am         C/G        F          C\nLet it be, let it be, let it be, let it be\nC                G              F  C/E Dm C\nWhisper words of wisdom, let it be`;

  const location = useLocation();
  const item = location.state?.item;

  const navigate = useNavigate();

  const [editMode, setEditMode] = useState(false);
  const [songName, setSongName] = useState("");
  const [songAuthor, setSongAuthor] = useState("");
  const [songType, setSongType] = useState("canto");
  const [songSpeed, setSongSpeed] = useState("rapido");
  const [songKey, setSongKey] = useState("C");
  const [songKeyPref, setSongKeyPref] = useState("C");
  const [videoLink, setVideoLink] = useState("");
  const [oldChordSheet, setOldChordSheet] = useState("");
  const [chordSheet, setChordSheet] = useState("");
  const [isQROpen, setQRScanModal] = useState(false);
  const [chordSheetFontSize, setChordSheetFontSize] = useState("14px");

  const { userID, isLoading, setLoading, auth } = useAuth();
  const { darkMode } = useDarkMode();

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:601px) and (max-width:900px)"
  );

  const handleClose = () => {
    let closeModal = false;

    if (editMode) {
      if (
        confirm(
          "¿Salir sin guardar? Todos los cambios que no se guarden se perderán."
        ) == true
      ) {
        closeModal = true;
      }
    } else {
      closeModal = true;
    }

    if (closeModal) {
      setEditMode(false);
      setChordSheet(chordSheetTemplate);
      setOldChordSheet("");
      navigate("/repository");
    }
  };

  const QRDataRead = async (data) => {
    await fetchChordsheetData(data.f)
      .then(() => {
        setSongName(data.n);
        setSongAuthor(data.a);
        setSongType(data.t);
        setSongSpeed(data.s);
        setSongKey(data.k);
        setSongKeyPref(data.k);
        setVideoLink(data.v);
        setLoading(false);
        setQRScanModal(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        alert("Unknown error occurred.");
      });
  };

  const resetData = () => {
    setLoading(item?.name == null ? false : true);
    setSongName(item?.name == null ? "" : item?.name);
    setSongAuthor(item?.author == null ? "" : item?.author);
    setSongType(item?.type == null ? "canto" : item?.type);
    setSongSpeed(item?.speed == null ? "rapido" : item?.speed);
    setSongKey(item?.key == null ? "C" : item?.key);
    setSongKeyPref(item?.key == null ? "C" : item?.key);
    setVideoLink(item?.video == null ? "" : item?.video);
    setChordSheet(oldChordSheet);
  };

  const fetchChordsheetData = async (item) => {
    fetch(item)
      .then((response) => response.text())
      .then((text) => {
        setChordSheet(text);
        setOldChordSheet(text);
        setLoading(false);
      });
  };

  useEffect(() => {
    resetData();
    if (item === null || item === undefined) {
      if (userID !== "") {
        setChordSheet(chordSheetTemplate);
        setEditMode(true);
        setChordSheetFontSize(
          isSmallScreen ? "14px" : isMediumScreen ? "20px" : "26px"
        );
      } else {
        navigate("/repository");
      }
    }
  }, [item]);

  useEffect(() => {
    if (item?.file) {
      fetchChordsheetData(item.file);
      setChordSheetFontSize(
        isSmallScreen ? "14px" : isMediumScreen ? "20px" : "26px"
      );
    }
  }, [item]);

  return (
    <div
      style={{
        backgroundColor: darkMode ? Colors.darkgrey : "white",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "90vw",
            maxWidth: isSmallScreen
              ? "600px"
              : isMediumScreen
              ? "750px"
              : "900px",
          }}
        >
          <IconButton onClick={handleClose} aria-label="close">
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>

      <QRScanModal
        isQROpen={isQROpen}
        setQRScanModal={setQRScanModal}
        QRDataReadCallback={QRDataRead}
        isLoading={isLoading}
        setLoading={setLoading}
      />

      {editMode ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              width: "90vw",
              maxWidth: isSmallScreen
                ? "600px"
                : isMediumScreen
                ? "750px"
                : "900px",
            }}
          >
            <TextField
              fullWidth
              label="Titulo"
              value={songName}
              onChange={(event) => setSongName(event.target.value)}
              inputProps={{ maxLength: 40 }}
            />
            <TextField
              fullWidth
              label="Autor"
              value={songAuthor}
              sx={{ my: 2 }}
              onChange={(event) => setSongAuthor(event.target.value)}
              inputProps={{ maxLength: 40 }}
            />
            <Box
              sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}
            >
              <Button
                variant="contained"
                style={{ fontSize: isSmallScreen ? "10px" : "12px" }}
                onClick={() => setQRScanModal(true)}
              >
                Escanear QR
              </Button>
              <Select
                size="small"
                value={songType}
                onChange={(event) => setSongType(event.target.value)}
              >
                <MenuItem value={"canto"}>canto</MenuItem>
                <MenuItem value={"himno"}>himno</MenuItem>
              </Select>
              <Select
                size="small"
                value={songSpeed}
                onChange={(event) => setSongSpeed(event.target.value)}
              >
                <MenuItem value={"rapido"}>rapido</MenuItem>
                <MenuItem value={"despacio"}>despacio</MenuItem>
              </Select>
              <Select
                size="small"
                defaultValue={item?.key ?? "C"}
                value={songKey}
                onChange={(event) => setSongKey(event.target.value)}
              >
                <MenuItem value={"C"}>C</MenuItem>
                <MenuItem value={"C#"}>C#</MenuItem>
                <MenuItem value={"D"}>D</MenuItem>
                <MenuItem value={"D#"}>D#</MenuItem>
                <MenuItem value={"E"}>E</MenuItem>
                <MenuItem value={"F"}>F</MenuItem>
                <MenuItem value={"F#"}>F#</MenuItem>
                <MenuItem value={"G"}>G</MenuItem>
                <MenuItem value={"G#"}>G#</MenuItem>
                <MenuItem value={"A"}>A</MenuItem>
                <MenuItem value={"A#"}>A#</MenuItem>
                <MenuItem value={"B"}>B</MenuItem>
              </Select>
            </Box>
            <br />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              width: "90vw",
              maxWidth: isSmallScreen
                ? "600px"
                : isMediumScreen
                ? "750px"
                : "900px",
            }}
          >
            <Typography variant="h5" style={{ color: darkMode && "white" }}>
              {item?.name}
            </Typography>
            <Typography variant="h6" style={{ color: darkMode && "white" }}>
              Autor: {item?.author}
            </Typography>
            <Typography variant="h6" style={{ color: darkMode && "white" }}>
              Velocidad: {item?.speed}
            </Typography>
            <Typography
              display="inline"
              style={{ color: darkMode && "white" }}
              variant="h6"
            >
              Tono:
            </Typography>
            <Select
              sx={{ mx: 1 }}
              defaultValue={item?.key ?? "C"}
              size="small"
              value={songKeyPref}
              onChange={(event) => {
                setSongKeyPref(event.target.value);
                try {
                  let parser = new ChordSheetJS.UltimateGuitarParser();
                  let tempChordSheet = parser.parse(oldChordSheet);
                  let currChordSheet = tempChordSheet.setKey(songKey);
                  let newSheet = currChordSheet.changeKey(event.target.value);
                  const formatter = new ChordSheetJS.TextFormatter();
                  const outputText = formatter.format(newSheet);
                  setChordSheet(outputText);
                } catch (error) {
                  console.error("Error parsing chord sheet:", error);
                }
              }}
            >
              <MenuItem value={"C"}>C</MenuItem>
              <MenuItem value={"C#"}>C#</MenuItem>
              <MenuItem value={"D"}>D</MenuItem>
              <MenuItem value={"D#"}>D#</MenuItem>
              <MenuItem value={"E"}>E</MenuItem>
              <MenuItem value={"F"}>F</MenuItem>
              <MenuItem value={"F#"}>F#</MenuItem>
              <MenuItem value={"G"}>G</MenuItem>
              <MenuItem value={"G#"}>G#</MenuItem>
              <MenuItem value={"A"}>A</MenuItem>
              <MenuItem value={"A#"}>A#</MenuItem>
              <MenuItem value={"B"}>B</MenuItem>
            </Select>
            <PrintChordSheet title={item?.name} text={chordSheet} />
            <QRShareModal
              data={JSON.stringify({
                f: item?.file.replace(
                  "https://firebasestorage.googleapis.com/v0/b/lalista-9cb88.appspot.com/o/",
                  ""
                ),
                n: item?.name,
                a: item?.author,
                t: item?.type,
                v: item?.video,
                k: item?.key,
                s: item?.speed,
              })}
            />
          </Box>
        </Box>
      )}

      {!editMode && (
        <FontSizeAdjuster
          initialFontSize={parseInt(
            (isSmallScreen
              ? "14px"
              : isMediumScreen
              ? "20px"
              : "26px"
            ).substring(0, 2)
          )}
          currFontSize={parseInt(chordSheetFontSize.substring(0, 2))}
          setFontSize={setChordSheetFontSize}
        />
      )}

      {isLoading && !editMode && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mt: 3,
          pb: 3,
        }}
      >
        {editMode ? (
          <>
            <TextareaAutosize
              id="home-chord-sheet"
              placeholder="Vacio"
              value={chordSheet}
              onChange={(event) => {
                setChordSheet(event.target.value);
              }}
              style={{
                width: "90vw",
                maxWidth: isSmallScreen
                  ? "600px"
                  : isMediumScreen
                  ? "750px"
                  : "900px",
                padding: "12px",
                borderRadius: "6px",
                resize: "none",
                fontSize: chordSheetFontSize,
                fontFamily: "Roboto Mono, monospace",
                backgroundColor: darkMode ? Colors.darkgrey : "white",
                color: darkMode ? "white" : "black",
              }}
            />
            {item !== null && (
              <p
                style={{
                  paddingInline: 20,
                  textAlign: "center",
                  marginTop: 10,
                  color: darkMode && "white",
                }}
              >
                Si no hay cambio en los acordes, se utilizarán los últimos
                acordes.
              </p>
            )}
          </>
        ) : (
          <TextareaAutosize
            placeholder="Vacio"
            readOnly
            value={chordSheet}
            style={{
              width: "90vw",
              maxWidth: isSmallScreen
                ? "600px"
                : isMediumScreen
                ? "750px"
                : "900px",
              padding: "12px",
              borderRadius: "6px",
              resize: "none",
              fontSize: chordSheetFontSize,
              fontFamily: "Roboto Mono, monospace",
              backgroundColor: darkMode ? Colors.darkgrey : "white",
              color: darkMode ? "white" : "black",
            }}
          />
        )}
      </Box>

      {editMode ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              width: "90vw",
              maxWidth: isSmallScreen
                ? "600px"
                : isMediumScreen
                ? "750px"
                : "900px",
            }}
          >
            <TextField
              fullWidth
              label="Enlace de Video"
              value={videoLink}
              onChange={(event) => setVideoLink(event.target.value)}
              sx={{ my: 2 }}
            />
          </Box>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", pb: 5 }}>
          <iframe
            onLoad={() => setLoading(false)}
            onError={() => setLoading(false)}
            style={{ maxHeight: "100%", maxWidth: "100%" }}
            src={item?.video}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
      )}

      {!editMode && userID !== "" && (
        <Box sx={{ display: "flex", justifyContent: "center", pb: 5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "90vw",
              maxWidth: isSmallScreen
                ? "600px"
                : isMediumScreen
                ? "750px"
                : "900px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setChordSheet(oldChordSheet);
                setSongKeyPref(item?.key);
                setEditMode(true);
              }}
            >
              Edit
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "darkred",
                },
              }}
              onClick={() => {
                if (confirm("¿Estás seguro de que quieres eliminar?") == true)
                  deleteFromDB(
                    item,
                    (choice) => setLoading(choice),
                    auth.currentUser.email
                  );
              }}
            >
              Delete
            </Button>
          </Box>
        </Box>
      )}
      {editMode && userID !== "" && (
        <Box sx={{ display: "flex", justifyContent: "center", pb: 5 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "90vw",
              maxWidth: isSmallScreen
                ? "600px"
                : isMediumScreen
                ? "750px"
                : "900px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "grey",
                "&:hover": {
                  backgroundColor: "dimgrey",
                },
              }}
              onClick={
                item !== null
                  ? () => {
                      if (
                        confirm(
                          "¿Salir sin guardar? Todos los cambios que no se guarden se perderán."
                        ) == true
                      ) {
                        resetData();
                        setEditMode(false);
                      }
                    }
                  : handleClose
              }
            >
              Cancel
            </Button>
            {isLoading && <CircularProgress />}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "green",
                "&:hover": {
                  backgroundColor: "darkgreen",
                },
              }}
              onClick={
                item !== null
                  ? () =>
                      updateInDB(
                        {
                          id: item.id,
                          file: item.file,
                          key: songKey,
                          name: songName,
                          author: songAuthor,
                          type: songType,
                          speed: songSpeed,
                          video: videoLink,
                          performNum: item.performNum,
                          repoLastAuthor: auth.currentUser.email,
                        },
                        chordSheet === oldChordSheet ? null : chordSheet,
                        (choice) => setLoading(choice)
                      )
                  : () =>
                      addToDB(
                        {
                          key: songKey,
                          name: songName,
                          author: songAuthor,
                          type: songType,
                          speed: songSpeed,
                          video: videoLink,
                          repoLastAuthor: auth.currentUser.email,
                        },
                        chordSheet,
                        (choice) => setLoading(choice)
                      )
              }
            >
              Save
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
}
