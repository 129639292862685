import React, { useRef, useState } from "react";
import {
  Button,
  Modal,
  Fade,
  Typography,
  IconButton,
  useMediaQuery,
  Box,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QrReader from "react-qr-reader";
import { useDarkMode } from "../context/DarkModeContext";
import Colors from "../data/Colors";

export default function QRScanModal({
  isQROpen,
  setQRScanModal,
  QRDataReadCallback,
  isLoading,
  setLoading,
}) {
  const [QRCamera, setQRCamera] = useState(false);
  const codeReader = useRef(null);
  const [isLegacyOn, setLegacyOn] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const { darkMode } = useDarkMode();

  const handleCloseSecondModal = () => {
    setQRCamera(false);
    setQRScanModal(false);
  };

  const handleScanQRCode = async (data) => {
    if (data !== null) {
      setLoading(true);

      let expandedData = null;

      try {
        expandedData = JSON.parse(data);
      } catch (error) {
        setLoading(false);
        alert("Invalid QR");
        return;
      }

      if (expandedData.f !== "") {
        expandedData.f =
          "https://firebasestorage.googleapis.com/v0/b/lalista-9cb88.appspot.com/o/" +
          expandedData.f;
        QRDataReadCallback(expandedData);
      } else {
        setLoading(false);
        alert("Invalid QR");
      }
    }
  };

  const openCamera = () => {
    if (isLegacyOn) setLegacyOn(false);
    setQRCamera(true);
  };

  const openImage = () => {
    if (!isLegacyOn) setLegacyOn(true);
    setQRCamera(true);

    setTimeout(() => {
      codeReader.current.openImageDialog();
    }, "1");
  };

  return (
    <Modal open={isQROpen} onClose={handleCloseSecondModal}>
      <Fade in={isQROpen}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: { xs: "70vw", md: "25vw" },
            transform: "translate(-50%, -50%)",
            backgroundColor: darkMode ? Colors.darkgrey : "white",
            padding: isSmallScreen ? "10px" : "20px",
            borderRadius: "8px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleCloseSecondModal} aria-label="close">
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          <Typography variant="h4" style={{ color: darkMode && "white" }}>
            Elige cómo importar:
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              my: 2,
            }}
          >
            <Button
              variant="contained"
              style={{ fontSize: isSmallScreen ? "12px" : "14px" }}
              onClick={openCamera}
            >
              Escanear QR
            </Button>
            <Button
              variant="contained"
              style={{ fontSize: isSmallScreen ? "12px" : "14px" }}
              onClick={openImage}
            >
              Subir QR
            </Button>
          </Box>

          {QRCamera && (
            <div>
              {!isLegacyOn && (
                <Typography
                  variant="subtitle1"
                  style={{ color: darkMode && "white" }}
                >
                  Pone el QR code en frente de la camara.
                </Typography>
              )}
              <QrReader
                ref={codeReader}
                facingMode="environment"
                style={{ height: "fit-content", width: "auto" }}
                onError={(err) => console.error(err)}
                onScan={handleScanQRCode}
                legacyMode={isLegacyOn}
              />
            </div>
          )}

          {isLoading && <CircularProgress />}
        </Box>
      </Fade>
    </Modal>
  );
}
