import React, { useEffect, useMemo } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import { Buffer } from "buffer";
import { useDarkMode } from "../context/DarkModeContext";
import Colors from "../data/Colors";

const fetchAndSaveFile = async (item, setLoading) => {
  try {
    const response = await fetch(item.file);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const textContent = await response.text();
    const buff = Buffer.from(textContent, "utf-8");
    const base64Content = buff.toString("base64");

    const myObject = {
      url: item.file,
      data: base64Content,
    };

    window.localStorage.setItem(`${item.id}`, JSON.stringify(myObject));

    setLoading(false);
  } catch (error) {
    console.error("Error fetching or saving the file:", error);
  }
};

const SongList = ({
  setLoading,
  songs,
  setDocument,
  setCurrKey,
  setKeyPref,
  selectedIndex,
  setSelectedIndex,
  setCurrListKeyID,
  setVideoLink,
}) => {
  const { darkMode } = useDarkMode();

  useEffect(() => {
    songs.forEach((item) => {
      fetchAndSaveFile(item, setLoading);
    });
  }, [songs, setLoading]);

  const handleListItemClick = (ID, video, key, keyPref, listKeyID, index) => {
    if (selectedIndex !== index) {
      setLoading(true);
      setSelectedIndex(index);
      setCurrListKeyID(listKeyID);
      const obj = JSON.parse(window.localStorage.getItem(`${ID}`));
      setDocument(obj.data);
      setCurrKey(key);
      setKeyPref(keyPref);
      setVideoLink(video);
    }
  };

  const memoizedSongs = useMemo(() => songs, [songs]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 360,
          border: 1,
          borderColor: "lightgrey",
        }}
      >
        <List disablePadding>
          {memoizedSongs.map((item, index) => (
            <React.Fragment key={item.listKeyID}>
              <ListItem
                secondaryAction={
                  <ListItemText
                    style={{ color: darkMode ? "white" : "black" }}
                    primary={item.keyPref}
                  />
                }
                disablePadding
                style={{
                  backgroundColor: darkMode ? Colors.darkgrey : "transparent",
                }}
              >
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={() =>
                    handleListItemClick(
                      item.id,
                      item.video,
                      item.key,
                      item.keyPref,
                      item.listKeyID,
                      index
                    )
                  }
                >
                  <ListItemText
                    style={{ color: darkMode ? "white" : "black" }}
                    primary={item.name}
                  />
                </ListItemButton>
              </ListItem>
              {index !== memoizedSongs.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default SongList;
