import * as React from "react";
import ReactDOM from "react-dom/client";
import { StyledEngineProvider } from "@mui/material/styles";
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/AuthContext";
import DarkModeProvider from "./context/DarkModeContext";
import * as serviceWorker from "./serviceWorker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

import "./firebase.js";

ReactDOM.createRoot(document.querySelector("#root")).render(
  <AuthProvider>
    <DarkModeProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StyledEngineProvider>
      </LocalizationProvider>
    </DarkModeProvider>
  </AuthProvider>
);

serviceWorker.register();
