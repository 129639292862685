import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Popover,
  List,
  ListItemText,
  ListItemButton,
} from "@mui/material";

const options = [
  "Nombre de A a Z",
  "Nombre de Z a A",
  "Veces Tocado ↑",
  "Veces Tocado ↓",
];

const SortByPopover = ({ execFunc, disabled, stateData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelected(0);
  }, [stateData]);

  const handleListItemClick = useCallback(
    (index) => {
      if (selected !== index) {
        setSelected(index);
        execFunc(index);
        handleClose();
      }
    },
    [execFunc, selected]
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        disabled={disabled}
        onClick={handleClick}
        size="small"
      >
        Ordernar Por
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List>
          {options.map((option, index) => (
            <ListItemButton
              key={option}
              onClick={() => handleListItemClick(index)}
              component="a"
            >
              <ListItemText primary={option} />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default SortByPopover;
